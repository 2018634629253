import { CdkScrollable, CdkScrollableModule } from '@angular/cdk/scrolling';
import { DOCUMENT, JsonPipe, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  input,
  model,
  signal,
  untracked,
  viewChild,
  viewChildren,
} from '@angular/core';
import { outputFromObservable, takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

import { LoadingPlaceholderComponent } from 'common-ui';
import { ElementResizeDirective, MeasureComponent } from 'common-util';
import { debounceTime, filter, first, fromEvent, takeUntil, tap } from 'rxjs';
import {
  FixedGroupingStrategy,
  FixedGroupingStrategyFn,
  GroupingStrategy,
  isFixedGroupingStrategy,
  MaxItemFitGroupingStrategy,
  MaxItemSizeGroupingStrategy,
  MaxItemsPerGroupStrategy,
} from '../lists';
import { CarouselItemDirective } from './items';
import { CarouselItemInfo, CarouselItemTemplateContext } from './types';

const measureItemInfo: CarouselItemInfo = {
  index: 0,
  count: 1,
  first: true,
  last: true,
  even: false,
  odd: true,
};

@Component({
  selector: 'ideal-carousel-layout',
  standalone: true,
  imports: [NgTemplateOutlet, CdkScrollableModule, MeasureComponent, LoadingPlaceholderComponent, JsonPipe],
  templateUrl: './carousel-layout.component.html',
  styleUrl: './carousel-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{ directive: ElementResizeDirective, outputs: ['sizeChange'] }],
  host: {
    '[style.--items-per-page]': 'itemsPerPage()',
    '[style.--item-height]': 'itemHeight() + "px"',
    '[style.--item-size]': 'itemSize() + "px"',
    '[style.--grid-size]': 'measuredContainer() + "px"',
    '[class.dragging]': '_dragging()',
  },
})
export class CarouselLayoutComponent<TData> {
  private readonly _document = inject(DOCUMENT);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _resizeDirective = inject(ElementResizeDirective, { self: true });
  private readonly _measure = viewChild.required<MeasureComponent>(MeasureComponent);
  private readonly _itemTemplateDirective = contentChild.required(CarouselItemDirective, { descendants: true });
  private readonly _cdkScrollable = viewChild.required(CdkScrollable);
  //private readonly _scrolled$ = this._cdkScrollable().elementScrolled().pipe(debounceTime(50));

  private readonly _pageElmRefs = viewChildren<string, ElementRef<HTMLElement>>('pageContainer', { read: ElementRef });
  private _dragging = signal<boolean>(false);
  private _dragStartPos = 0;
  private _dragStartScrollPos = 0;
  private _itemContexts = new Map<
    TData,
    CarouselItemInfo & {
      $implicit: TData;
    }
  >();

  public readonly GroupingStrategies = {
    MaxItemsPerPage: MaxItemsPerGroupStrategy,
    MaxItemSize: MaxItemSizeGroupingStrategy,
    MaxItemFit: MaxItemFitGroupingStrategy,
    FixedColumns: FixedGroupingStrategy,
  } as const;

  public readonly data = input.required<TData[]>();
  public readonly layoutStrategy = input<
    GroupingStrategy,
    GroupingStrategy | 'maxItemsPerPage' | 'maxItemSize' | 'maxItemFit' | number | undefined
  >(this.GroupingStrategies.MaxItemsPerPage, {
    transform: (v) => {
      switch (v) {
        case 'maxItemsPerPage':
          return this.GroupingStrategies.MaxItemsPerPage;
        case 'maxItemSize':
          return this.GroupingStrategies.MaxItemSize;
        case 'maxItemFit':
          return this.GroupingStrategies.MaxItemFit;
        case undefined:
          return this.GroupingStrategies.MaxItemsPerPage;
        default:
          if (typeof v === 'number') {
            return this.GroupingStrategies.FixedColumns(v);
          }
          return v;
      }
    },
  });

  protected readonly firstItem = computed(() => this.data()?.[0]);
  public readonly pageIndex = model(0);

  protected readonly measuredMinSize = computed(() => this._measure().minSize());
  protected readonly measuredMaxSize = computed(() => this._measure().maxSize());
  protected readonly measuredGaps = computed(() => this._measure().gapSize());
  protected readonly measuredContainer = computed(() => this._resizeDirective.width());

  protected readonly isFixedColumns = computed(() => isFixedGroupingStrategy(this.layoutStrategy()));
  protected readonly fixedColumnWidthStyle = computed(() => {
    if (!this.isFixedColumns()) return null;

    const measuredContainer = this.measuredContainer();
    const measuredGap = this.measuredGaps().width;
    const strategy = this.layoutStrategy() as FixedGroupingStrategyFn;
    const cols = strategy(measuredContainer, measuredGap);

    const totalGap = measuredGap * (cols - 1);
    return `calc((100% - ${totalGap}px) / ${cols})`;
  });

  public readonly itemsPerPage = computed(() => {
    const measuredContainer = this.measuredContainer();
    if (measuredContainer === 0) {
      return 0;
    }
    const layoutStrategy = this.layoutStrategy();
    return layoutStrategy(measuredContainer, this.measuredMinSize().width, this.measuredMaxSize().width, this.measuredGaps().width);
  });

  public readonly itemsPerPageChange = outputFromObservable(toObservable(this.itemsPerPage));

  public readonly hasNextPage = computed(() => this.pageIndex() < this.dataPages().length - 1);
  public readonly hasPrevPage = computed(() => this.pageIndex() > 0);

  protected readonly itemHeight = computed(() =>
    this.isFixedColumns() ? this.measuredMaxSize().height : Math.max(this.measuredMinSize().height, this.measuredMaxSize().height),
  );
  public readonly itemHeightChange = outputFromObservable(toObservable(this.itemHeight));
  protected readonly itemSize = computed(() => this.itemHeight() + this.measuredGaps().height);

  protected readonly itemTemplate = computed(() => this._itemTemplateDirective().template);
  protected makeItemContext(
    item: TData,
    rowInfo: Partial<CarouselItemInfo> = measureItemInfo,
  ): {
    $implicit: TData;
  } & CarouselItemInfo {
    if (this._itemContexts.has(item)) {
      return this._itemContexts.get(item)!;
    }

    const context = {
      $implicit: item,
      count: this.data().length,
      ...rowInfo,
    } as CarouselItemTemplateContext<TData>;
    this._itemContexts.set(item, context);
    return context;
  }

  protected readonly dataPages = computed(() => {
    const itemsPerPage = this.itemsPerPage();
    if (itemsPerPage === 0) {
      return [];
    }
    const list = this.data();
    if (itemsPerPage === 1) {
      return list.map((item) => [item]);
    }
    const rows = Array.from({ length: Math.ceil(list.length / itemsPerPage) }).map((_, i) =>
      list.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage),
    );
    return rows;
  });

  constructor() {
    effect(() => {
      const pageIndex = this.pageIndex();
      const cdkScrollable = this._cdkScrollable();
      const pageElmRefs = this._pageElmRefs();
      if (!pageElmRefs?.length) {
        return;
      }
      cdkScrollable.scrollTo({ left: pageElmRefs[pageIndex].nativeElement.offsetLeft, behavior: 'smooth' });
    });

    effect((cleanup) => {
      const cdkScrollable = this._cdkScrollable();
      const sub = cdkScrollable
        .elementScrolled()
        .pipe(
          filter(() => !this._dragging()),
          debounceTime(50),
        )
        .subscribe(() => {
          const scrollLeft = cdkScrollable.measureScrollOffset('left');
          const pageElmRefs = untracked(this._pageElmRefs);
          if (pageElmRefs?.length) {
            const selectPage = pageElmRefs.findIndex((elmRef) => elmRef.nativeElement.offsetLeft >= scrollLeft);
            if (selectPage >= 0) {
              this.scrollToPage(selectPage);
            }
          }
        });
      cleanup(() => sub.unsubscribe());
    });
  }

  public scrollToPage(index: number) {
    const newPage = Math.min(Math.max(index, 0), this.dataPages().length - 1);
    this.pageIndex.set(newPage);
  }

  public scrollToFirstPage() {
    this.scrollToPage(0);
  }

  public scrollToLastPage() {
    this.scrollToPage(this.dataPages().length - 1);
  }

  public scrollNext() {
    this.scrollToPage(this.pageIndex() + 1);
  }

  public scrollPrev() {
    this.scrollToPage(this.pageIndex() - 1);
  }

  protected onDragStart(event: MouseEvent) {
    this._dragging.set(true);
    this._dragStartPos = event.clientX;
    this._dragStartScrollPos = this._cdkScrollable().measureScrollOffset('left');
    fromEvent<MouseEvent>(this._document, 'mousemove')
      .pipe(
        takeUntil(
          fromEvent<MouseEvent>(this._document, 'mouseup').pipe(
            first(),
            tap((event) => {
              this._dragging.set(false);
              const delta = this._dragStartPos - event.clientX;
              if (delta > 50) {
                this.scrollNext();
              } else if (delta < -50) {
                this.scrollPrev();
              }
            }),
          ),
        ),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
    event.preventDefault();
  }
}
