<ideal-carousel-layout
  #layout
  [data]="data()"
  [(pageIndex)]="pageIndex"
  [layoutStrategy]="layoutStrategy()"
  (itemsPerPageChange)="perPage.set($event); itemsPerPageChange.emit($event)"
  (itemHeightChange)="itemHeightChange.emit($event)"
>
  <ng-container *carouselItem="let item; let index = index; let even = even; let odd = odd; let first = first; let last = last">
    <ng-container
      *ngTemplateOutlet="
        itemTemplate();
        context: {
          $implicit: item,
          index,
          first,
          last,
          even,
          odd,
        }
      "
    ></ng-container>
  </ng-container>
</ideal-carousel-layout>
@if (!hideNextAndPrev()) {
  <button prev (click)="layout.scrollPrev()" [disabled]="!layout.hasPrevPage()"><mat-icon>chevron_left</mat-icon></button>
  <button next (click)="layout.scrollNext()" [disabled]="!layout.hasNextPage()"><mat-icon>chevron_right</mat-icon></button>
}

@if (controlPosition() !== "hidden") {
  <mat-radio-group [value]="pageIndex()" (change)="pageIndex.set($event.value)">
    <div>
      @if (timerEnabled()) {
        <div>
          <button control mat-icon-button (click)="pauseTimer.set(!pauseTimer())" [class.paused]="pauseTimer()">
            <mat-icon play>play_arrow</mat-icon>
            <mat-icon pause>pause</mat-icon>
          </button>
        </div>
      }
      @if (!hidePageSelect()) {
        @for (item of indexItems(); track $index) {
          <div>
            <mat-radio-button [value]="$index"></mat-radio-button>
            @if (timerEnabled() && pageIndex() === $index && !pauseTimer()) {
              <mat-spinner mode="determinate" [value]="timerValue()" strokeWidth="20"></mat-spinner>
            }
          </div>
        }
      }
    </div>
  </mat-radio-group>
}
