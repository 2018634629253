<ideal-measure
  [template]="cellTemplate()"
  [context]="makeCellContext(firstItem())"
  [style.width]="fixedColumnWidthStyle() ?? '100%'"
></ideal-measure>

@let rows = dataRows();
@if (rows.length && itemSize()) {
  <div content>
    <ng-container *ngTemplateOutlet="scrollViewport" />
  </div>
}

<ng-template #scrollViewport>
  <cdk-virtual-scroll-viewport
    [itemSize]="itemSize()"
    [minBufferPx]="itemSize() * 3"
    [maxBufferPx]="itemSize() * 6"
    [appendOnly]="appendOnly()"
    [style.--grid-item-height]="itemHeight()"
  >
    <ng-container
      *cdkVirtualFor="
        let row of rows;
        let index = index;
        let count = count;
        let first = first;
        let last = last;
        let even = even;
        let odd = odd;
        trackBy: indexTrackFn
      "
    >
      <div class="row" [class.first]="first" [class.last]="last" [class.even]="even" [class.odd]="odd" [attr.row]="index">
        @for (col of row; track $index) {
          <!-- @if(col === null){ -->
          <div load-ph-container>
            <ideal-loading-placeholder [loading]="col === null"></ideal-loading-placeholder>
          </div>
          <!-- }@else { -->
          <ng-container
            *ngTemplateOutlet="
              cellTemplate();
              context: makeCellContext(
                col,
                {
                  index,
                  count,
                  first,
                  last,
                  even,
                  odd,
                },
                {
                  index: $index,
                  count: $count,
                  first: $first,
                  last: $last,
                  even: $even,
                  odd: $odd,
                }
              )
            "
          ></ng-container>
          <!-- } -->
        }
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>
