import { NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  effect,
  input,
  model,
  numberAttribute,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { distinctUntilChanged, endWith, from, map, share, startWith, switchMap, tap, timer, zipWith } from 'rxjs';
import { CarouselItemDirective, CarouselLayoutComponent } from '../carousel-layout';
import {
  FixedGroupingStrategy,
  GroupingStrategy,
  MaxItemFitGroupingStrategy,
  MaxItemSizeGroupingStrategy,
  MaxItemsPerGroupStrategy,
} from '../lists';

export enum CarouselControlsPosition {
  Hidden = 'hidden',
  Bottom = 'bottom',
  Top = 'top',
  OverlayBottom = 'overlay-bottom',
  OverlayTop = 'overlay-top',
}

@Component({
  selector: 'ideal-carousel',
  standalone: true,
  imports: [
    CarouselLayoutComponent,
    CarouselItemDirective,
    NgTemplateOutlet,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.control-position]': 'controlPosition()',
  },
})
export class CarouselComponent<TData> {
  private readonly _itemTemplateDirective = contentChild.required(CarouselItemDirective, { descendants: true });
  private readonly _carouselLayout = viewChild.required(CarouselLayoutComponent);

  public readonly data = input.required<TData[]>();
  public readonly hideNextAndPrev = input<boolean, unknown>(false, { transform: booleanAttribute });
  public readonly hidePageSelect = input<boolean, unknown>(false, { transform: booleanAttribute });
  public readonly controlPosition = input<CarouselControlsPosition>(CarouselControlsPosition.OverlayTop);
  public readonly pageIndex = model(0);

  public readonly GroupingStrategies = {
    MaxItemsPerPage: MaxItemsPerGroupStrategy,
    MaxItemSize: MaxItemSizeGroupingStrategy,
    MaxItemFit: MaxItemFitGroupingStrategy,
    FixedColumns: FixedGroupingStrategy,
  } as const;

  public readonly layoutStrategy = input<
    GroupingStrategy,
    GroupingStrategy | 'maxItemsPerPage' | 'maxItemSize' | 'maxItemFit' | number | undefined
  >(this.GroupingStrategies.MaxItemsPerPage, {
    transform: (v) => {
      switch (v) {
        case 'maxItemsPerPage':
          return this.GroupingStrategies.MaxItemsPerPage;
        case 'maxItemSize':
          return this.GroupingStrategies.MaxItemSize;
        case 'maxItemFit':
          return this.GroupingStrategies.MaxItemFit;
        case undefined:
          return this.GroupingStrategies.MaxItemsPerPage;
        default:
          if (typeof v === 'number') {
            return this.GroupingStrategies.FixedColumns(v);
          }
          return v;
      }
    },
  });

  public readonly itemsPerPageChange = output<number>();
  protected readonly perPage = signal<number>(0);
  public readonly itemsPerPage = this.perPage.asReadonly();

  public readonly itemHeightChange = output<number>();
  protected readonly itemTemplate = computed(() => this._itemTemplateDirective().template);
  protected readonly indexItems = computed(() => {
    if (this.itemsPerPage() < 1) {
      return [];
    }
    const dataLength = this.data().length;
    const itemsPerPage = this.itemsPerPage();
    return new Array(Math.ceil(dataLength / itemsPerPage)).fill(0).map((_, i) => i);
  });

  private readonly _timerStartTime = computed(() => {
    this.pageIndex();
    return Date.now();
  });

  public readonly pauseTimer = model<boolean>(false);

  public readonly pageDuration = input<number, unknown>(0, { transform: numberAttribute });

  protected timerEnabled = computed(() => this.pageDuration() > 0);

  protected readonly timerValue = toSignal(
    toObservable(this._timerStartTime).pipe(
      switchMap((startTime) => {
        if (this.pageDuration() === 0) {
          return from([0]);
        }
        const numUpdates = this.pageDuration() / 100;
        return from(new Array(numUpdates)).pipe(
          zipWith(timer(0, 100)),
          startWith(0),
          map(() => Math.max(startTime + this.pageDuration() - Date.now(), 0)),
          map((time) => 100 - (time / this.pageDuration()) * 100),
          endWith(100),
        );
      }),
      map((p) => (this.pauseTimer() ? 0 : p)),
      distinctUntilChanged(),
      tap((p) => {
        if (p >= 100) {
          setTimeout(() => {
            if (this._carouselLayout().hasNextPage()) {
              this._carouselLayout().pageIndex.set(this._carouselLayout().pageIndex() + 1);
            } else {
              this._carouselLayout().pageIndex.set(0);
            }
          }, 500);
        }
      }),
      share(),
    ),
    { initialValue: 0 },
  );

  constructor() {
    effect(
      () => {
        const paused = this.pauseTimer();
        if (!paused) {
          const layout = untracked(this._carouselLayout);
          if (untracked(layout.hasNextPage)) {
            layout.pageIndex.set(untracked(layout.pageIndex) + 1);
          } else {
            layout.pageIndex.set(0);
          }
        }
      },
      { allowSignalWrites: true },
    );
  }
}
